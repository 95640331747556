import React from "react";
import ContactForm from "../components/contact-form/ContactForm";
import HeroSplit from "../components/hero/HeroSplit";

const roleList = [
    "Software developers",
    "Software testers",
    "Project managers"
];

const text = "Are you a passionate, experienced IT professional with ambition? Then we should talk.<br><br>"
    +  "We cannot offer you a traditional job, but something better - the diversified experience of a freelancer, all the while maintaining financial stability. How?<br><br>"
    + "Our network gives you the opportunity to work for several prestigious companies. You will work on a freelance basis for our clients, but without the hassle of administration. And if nothing is lined up for you after a project is completed, you will still get your salary. You never have to worry.<br><br>"
    + "When we find someone with true potential, we’re in it for the long haul. We keep your salary competitive, and from the very beginning we will discuss your professional goals and how we can help you achieve them.<br><br>"
    + "So, are you who we’re looking for? If you believe so, introduce yourself via our <a class='pointer-large animsition-link p-style-large red-color' href='#contact'>contact form</a> and we'll be in touch.";


const WorkForUs = () => {
    return (
        <>
            <HeroSplit preTitle="Let's join forces" title={["And we'll",  "accellerate", "your career"]} backgroundImage="/assets/images/work-for-us/empty-desk.jpg" />
            <section id="down" className="dark-bg-1 top-bottom-padding-120">
                <div className="top-padding-90">
                    <div className="container small top-padding-60 flex-container response-999 services-content">
                        <div className="four-columns">
                            <div data-animation-container className="content-right-margin-20">
                                <h2 data-animation-child className="small-title-oswald red-color overlay-anim-box2" data-animation="overlay-anim2">Join the team</h2>
                                <h3 className="title-style text-color-4">
                                    <span data-animation-child className="overlay-anim-box2 overlay-light-bg-1 tr-delay01" data-animation="overlay-anim2">What we're</span><br />
                                    <span data-animation-child className="overlay-anim-box2 overlay-light-bg-1 tr-delay02" data-animation="overlay-anim2">looking for:</span><br />
                                </h3>
                                <ul className="list-dots">
                                    {roleList.map((role, i) => (
                                        <li key={i}>
                                            <p data-animation-child className={`p-letter-style text-color-4 fade-anim-box tr-delay0${i + 4}`} data-animation="fade-anim">{role}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="eight-columns">
                            <div className="content-left-margin-20">
                                <p className="p-style-large text-color-5 fade-anim-box tr-delay04" data-animation="fade-anim" dangerouslySetInnerHTML={{ __html: text, }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ContactForm />
        </>
       
    );
};

export default WorkForUs;
